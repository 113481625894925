// castle html content category
export const CASTLE_CONTENT_CATEGORY = {
  // conceptbooth: "castle-conceptbooth",
  selfie: "castle-selfie",
  dessert: "castle-dessert",
  serviceQna: "castle-service-qna",
  preservationQna: "castle-preservation-qna",
}

export const CONCEPT_BOOT_QNA = "castle-concept-qna"

export const AllTag = {
  id: "all",
  title: "전체",
}

export const Tags = [
  {
    id: "lovely",
    title: "러블리",
  },
  {
    id: "dreamy",
    title: "몽환",
  },
  {
    id: "cutie",
    title: "큐티",
  },
  {
    id: "fantastic",
    title: "판타지",
  },
  {
    id: "kitsch",
    title: "키치",
  },
  {
    id: "wedding",
    title: "웨딩",
  },
]

export const Offdays = ["2025-01-29","2025-03-01","2025-03-02","2025-03-03","2025-03-04","2025-03-05","2025-03-06","2025-03-07"]
export const Holidays = ["2025-01-30","2025-06-06","2025-08-15"]

export const PayOrderTypes = {
  OnlieReservation: "온라인예약",
  OnlineConceptBuy: "온라인키오스크컨셉구매",
  OnliePhotoTouch: "온라인추가보정신청",
  OnlineAddShot: "온라인키오스크추가촬영",
  OnlinePrint: "온라인인쇄",
}

export const AppVersion = 2502110

export const isMenuPage = (path) => {
  var ret = false
  switch (path) {
    case "/":
    case "/reservation/buy":
    case "/reservation/package":
    case "/castle":
    case "/makeup":
    case "/mypage/home":
      ret = true
      break
    default:
      if (path.startsWith("/eventPage")) {
        ret = true
      }
      break
  }
  return ret
}

export const DEFAULT_PACKAGE_CODE = "default-package"

export const RESERV_CAN_TIME_LIST = ["12:00", "15:00", "18:00"]
