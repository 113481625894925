import Vue from "vue"
import { AppVersion, DEFAULT_PACKAGE_CODE, PayOrderTypes, Tags } from "../constant"
import { kStorageUrl } from "@/models/PSDLayer"
import router from "../routes"
import dayjs from "dayjs"
import axios from "../api/restApi"
import authService from "../api/auth-service"
import i18n from "../i18n"
import storage from "@/methods/storage.js"

export const tossInit = ({ commit }, payload) => {
  let recaptchaScript = document.createElement("script")
  recaptchaScript.setAttribute("src", "https://js.tosspayments.com/v1")
  document.head.appendChild(recaptchaScript)
}

export const login = async ({ commit }, payload) => {
  commit("login", payload)

  console.log("login action", payload)

  const { qrcode, visitQrCode, pay_qrcode, callbackUrl, event, packageEvent, proudctId } = router.currentRoute.query

  try {
    await authService.updateLastLoginAt(payload.user_id)

    if (qrcode) {
      await authService.updateQrCode(payload.user_id, qrcode)
    } else if (visitQrCode) {
      await authService.updatePayQrcode(payload.user_id, visitQrCode)
    } else if (pay_qrcode) {
      await authService.updatePayQrcode(payload.user_id, pay_qrcode)
    }
  } catch (e) {
    if (payload.errorHandler) {
      payload.errorHandler()
    }
    return
  }

  if (callbackUrl) {
    router.replace(callbackUrl)
  } else if (qrcode) {
    router.replace("/?qrlogin=true")
  } else if (event) {
    router.replace(`/reservation/buy?event=${event}`)
  } else if (packageEvent) {
    let pathUrl = `/reservation/package?packageEvent=${packageEvent}`
    if (proudctId) {
      pathUrl += `&productId=${productId}`
    }
    router.replace(pathUrl)
  } else if (pay_qrcode) {
    router.replace(`/onlinePayConnect?pay_qrcode=${pay_qrcode}`)
  } else {
    router.replace("/")
  }
}

export const appVersionCheck = ({ commit, state }, payload) => {
  const realodTime = dayjs(state.app_reload_time)
  const diffMinutes = dayjs().diff(realodTime, "minute")
  const recentVersion = Number(payload)

  if (recentVersion && recentVersion > AppVersion && diffMinutes > 1) {
    console.log("app reload")
    router.go(0)
    commit("SET_APP_RELOAD_TIME", Date.now())
    return false
  }
  return true
}

export const getPromotionPrice = async ({ commit, dispatch }, payload) => {
  const result = await axios.get("/api/promotion/select")

  let defaultPrice = result.data.find((e) => e.code === "default")
  defaultPrice = {
    ...defaultPrice,
    lucid_card_price: 9900,
    lucid_print_price: 3900,
  }
  let defaultPackagePrice = result.data.find((e) => e.code === DEFAULT_PACKAGE_CODE)
  defaultPackagePrice = {
    ...defaultPackagePrice,
    defaultPackagePrice: 9900,
    defaultPackagePrice: 3900,
  }

  let packageCode = payload?.packageProductId || DEFAULT_PACKAGE_CODE

  let packagePrice = result.data.find((e) => e.code === packageCode)
  if (packagePrice) {
    packagePrice = {
      ...packagePrice,
      lucid_card_price: 9900,
      lucid_print_price: 3900,
    }
  }

  commit("SET_DEFAULT_PRICE", defaultPrice)
  commit("SET_DEFAULT_PACKAGE_PRICE", defaultPackagePrice)

  let promotionPrice = payload?.promotionCode ? result.data.find((e) => e.code == payload?.promotionCode) : null

  return { defaultPrice, defaultPackagePrice, packagePrice, promotionPrice }
}

// 사진 인화 요청
export const reqPicturePrint = async ({ commit, dispatch }, payload) => {
  const { user_id, imageUrl, conceptName, count, orderId, kiosk } = payload
  const result = await axios.post("/api/print/requestPrint", {
    user_id,
    imageUrl,
    conceptName,
    count,
    kiosk,
  })

  dispatch("updateUsedCoupons", {
    orderId,
    user_id,
  })

  router.replace({
    name: "printReqCompl",
    query: {
      orderId,
      kiosk,
    },
  })

  return result.data
}

// 쿠폰 사용완료 처리
export const updateUsedCoupons = ({ commit, dispatch }, payload) => {
  console.log("updateUsedCoupons", payload)
  const { orderId, user_id } = payload
  if (!orderId || !user_id) {
    return
  }
  axios
    .post("/api/order/v2/orderDetail", {
      orderId,
    })
    .then((orderDetailRes) => {
      const result = orderDetailRes.data.map((e) => e.couponId).filter((x) => x)
      if (result) {
        result.forEach((couponId) => {
          axios.post("/api/coupon/useCoupon", {
            registered_coupon_id: couponId,
            user_id,
          })
        })
      }
    })
}

//결제 연동 처리
export const doPayCompleted = async ({ commit, dispatch }, payload) => {
  const { orderNo, amount, paymentKey, isAbroad } = payload

  // 주문 정보 가져오기
  const product = (
    await axios.post("/api/order/select/check_duplicate", {
      params: {
        toss_id: orderNo,
      },
    })
  ).data[0]

  const { order_list_id, user_id, type, memo, pay_status, status } = product

  let tossPaymentConfirm = () => {

    // 결제완료 이면서 상태완료 이면 무시
    if (pay_status === "결제완료" && status === "결제완료") {
      return { data: { status: "DONE" } }
    }
    
    return axios.post("/api/payments/toss/confirm", {
      orderListId: order_list_id,
      paymentKey: paymentKey,
      orderId: orderNo,
      amount: Number(amount),
    })
    .catch(async (e) => {
      if (e.response.data.code === "ALREADY_PROCESSED_PAYMENT") {
        console.log("toss confirm ALREADY_PROCESSED_PAYMENT")
        // 이미 결제된 사항.
        return { data: { status: "DONE" } }
      } else {
        const errorMessage = e.response.data || "Unknown Error"
        await dispatch("cancelPayStatusUpdate", {
          order_id: order_list_id,
          reason: JSON.stringify(errorMessage),
        })
      }
      throw e
    })
  }

  let testTossPaymentConfirm = () => {
    return new Promise((resolve, reject) => {
      resolve({ data: { status: "DONE" } })
    })
  }

  let payletterConfirm = () => {
    return new Promise((resolve, reject) => resolve({ data: { status: "DONE" } }))
  }

  let payConfirmAsync = !isAbroad ? (paymentKey === "test" ? testTossPaymentConfirm : tossPaymentConfirm) : payletterConfirm

  let payConfirmResultData = (await payConfirmAsync()).data
  if (payConfirmResultData.status != "DONE") {
    alert(i18n.t("alert.error_doing_pay_unknown_error"))
    return
  }
  if ([PayOrderTypes.OnlineConceptBuy, PayOrderTypes.OnlineAddShot].includes(product.type)) {
    // 키오스크 연동 온라인 결제
    const result = await axios.post("/api/qrcode/pay/complete", {
      pay_amount: Number(amount),
      orderListId: order_list_id,
      currency: "KRW",
    })
    if (result.data.affectedRows) {
      dispatch("updateUsedCoupons", { orderId: orderNo, user_id })
      router.push("/onlinePaySuccess?id=" + orderNo)
    }
  } else if (product.type === PayOrderTypes.OnliePhotoTouch) {
    // 추가 보정
    const result = await axios.post("/api/qrcode/update/status/byOrderId", {
      params: {
        order_id: order_list_id,
        status: "선택완료",
      },
    })
    Vue.$toast.success(i18n.t("toast.succes-photo-effect-request"))
    dispatch("updateUsedCoupons", { orderId: orderNo, user_id })
    router.push("/mypage/home?type=picture")
  } else if (product.type === PayOrderTypes.OnlinePrint) {
    // 인쇄 결제
    let info = JSON.parse(memo)
    info = {
      ...info,
      imageUrl: info.printUrl || storage.imageUrl(`${kStorageUrl}/complete/${info.fileName}`, info.updated),
      orderId: orderNo,
    }
    dispatch("reqPicturePrint", info)
  } else {
    // 예약 결제 ( 드레스 예약, 패키지 구매 )
    /*
    let tossTimeCheckAsync = () => {
      return axios.post("/api/reservation/select/specific/time/castle/check", {
        params: {
          year: product.year,
          month: product.month,
          day: product.day,
          time: product.time,
        },
      })
    }

    let payLetterTimecheck = () => {
      return new Promise((resolve, reject) => resolve({ data: { status: "DONE" } }))
    }
    let precheck = !isAbroad ? tossTimeCheckAsync : payLetterTimecheck
    const availableRoomsChceckRes = await precheck().data

    if (availableRoomsChceckRes.length > 0) {
      if (product.cloth + availableRoomsChceckRes.total_cloth > 15) {
        alert(i18n.t("alert.error_max_reservation_error"))
        router.replace("/")
        return
      }
    } */

    dispatch("updateUsedCoupons", { orderId: orderNo, user_id })
    router.push("/reservation/complete?id=" + orderNo)
  }

  //결제완료
  Vue.$gtag.purchase({
    transaction_id: orderNo,
    value: Number(amount),
    currency: "KRW",
    items: [
      {
        item_name: type,
        price: Number(amount),
      },
    ],
  })
}

export const loadConcepts = async ({ commit, dispatch }, payload) => {
  console.log("loadConcepts")
  const concepts = (await axios.post("/api/concept/select")).data
  commit("SET_CONCEPTS", concepts)
  return concepts
}

export const loadConceptsAll = async ({ commit, dispatch }, payload) => {
  console.log("loadConceptsAll")
  const concepts = (
    await axios.post("/api/concept/select/search", {
      params: {
        keyword: "",
      },
    })
  ).data
  return concepts
}

export const cancelPayStatusUpdate = async ({ commit, dispatch }, payload) => {
  const res = await axios.post("/api/order/update/status", {
    params: {
      status: "결제취소",
      reason: payload.reason || "사용자취소",
      id: payload.order_id,
    },
  })
  return res
}
